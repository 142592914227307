import { useState } from "react";
import { Modal, Form, Spinner, Button, Alert } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import uuid from "react-uuid";
import { useStores } from "../../hooks/useStores";

function EditModal({ titleText, btnText, formData, updatedData }) {
  const [show, setShow] = useState(false);
  const [storeName, setStoreName] = useState(formData.name);
  const [physicalAddress, setPhysicalAddress] = useState(
    formData.physical_address
  );

  const [spinnerOn, setSpinnerOn] = useState(false);
  const [alert, setAlert] = useState(null);

  const { updateStores } = useStores();

  const handleClose = () => {
    setStoreName(formData.name);
    setPhysicalAddress(formData.physical_address);
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinnerOn(true);
    const newStoreData = {
      store_id: formData.store_id,
      physical_address: physicalAddress,
      owner_username: formData.owner_username,
      name: storeName,
    };
    const response = await updateStores(newStoreData);
    if (response) {
      setSpinnerOn(false);
      updatedData(newStoreData);
      setShow(false);
    } else {
      setSpinnerOn(false);
      setAlert({
        variant: "danger",
        message: "Error creating the store. Please try again.",
      });
    }
  };

  return (
    <>
      <Tooltip
        title="Edit Store"
        placement="top"
        arrow
        //followCursor={true}
        leaveDelay={20}
      >
        <button action="edit" onClick={handleShow}>
          <FaRegEdit />
        </button>
      </Tooltip>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{titleText}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Form
            id="updateStoreForm"
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Store ID</Form.Label>
              <Form.Control
                type="text"
                placeholder={formData.store_id}
                disabled
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Store Owner</Form.Label>
              <Form.Control
                type="text"
                placeholder={formData.owner_username}
                disabled
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Physical Address</Form.Label>
              <Form.Control
                type="text"
                placeholder={physicalAddress}
                onChange={(e) => {
                  setPhysicalAddress(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Store Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={storeName}
                onChange={(e) => {
                  setStoreName(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {spinnerOn ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" form="updateStoreForm" type="submit">
                {btnText}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditModal;
