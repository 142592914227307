import React, { useState } from "react";
import Layout from "../../layout/Layout/Layout";
import StoreExport from "../../components/Tables/StoreExport";
import "./exports.scss";
import ExportsCards from "./ExportsCards";
import newMaster from "../../assets/images/newMaster.png";
import imageSales from "../../assets/images/sales.png";
import imageConfig from "../../assets/images/config.png";
import { Spinner } from "react-bootstrap";

const Exports = () => {
  const [selectedStore, setSelectedStore] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);

  const handleStoreSelection = (store) => {
    setSelectedStore(store);
  };

  const showLoadingIndicator = (val) => {
    setIsDownloading(val);
  };

  return (
    <Layout>
      {isDownloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spinner variant="primary" />
        </div>
      ) : (
        <div className="exportsContainer">
          <div className="exportsTitle">
            <p style={{ fontSize: "16px" }}>
              *User can choose the store he wants to export the data from.
            </p>
            <StoreExport handleStoreSelection={handleStoreSelection} />
          </div>

          {selectedStore && (
            <>
              <p style={{ marginTop: "20px", fontSize: "22px" }}>
                Selected Store: {selectedStore}
              </p>
              <div className="exportsCards">
                <ExportsCards
                  img={imageSales}
                  title={"Sales File"}
                  chosenFile={"sales"}
                  storeId={selectedStore}
                  downloadingFiles={showLoadingIndicator}
                  desc={"This file contains the enhanced sales file."}
                />
                <ExportsCards
                  img={imageConfig}
                  chosenFile={"config"}
                  storeId={selectedStore}
                  title={"Configuration File"}
                  downloadingFiles={showLoadingIndicator}
                  desc={
                    "This file contains the configuration of the store as captured by the mobile app."
                  }
                />
                <ExportsCards
                  img={newMaster}
                  chosenFile={"master"}
                  storeId={selectedStore}
                  title={"Master file"}
                  downloadingFiles={showLoadingIndicator}
                  desc={
                    "This file contains the newly added products for the selected store."
                  }
                />
              </div>
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Exports;
