import axios from "axios";
import * as Constants from "../../utils/constants";

export const getSales = async (token, storeId) => {
  try {
    const response = await axios.get(`${Constants.EXPORT_SALES}/${storeId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "blob",
      },
      responseType: "arraybuffer",
    });
    return response;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

export const getMaster = async (token) => {
  try {
    const response = await axios.post(Constants.EXPORT_MASTER, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};
