import axios from "axios";
import * as Constants from "../../utils/constants";

export const loginUser = async (username, password) => {
  try {
    const response = await axios.post(Constants.LOGIN, {
      username: username,
      password: password,
      app: "WA",
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const verifyToken = async (token) => {
  try {
    const response = await axios.post(Constants.VERIFY_TOKEN, {
      token: token,
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};
