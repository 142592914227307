import React from "react";
import { FaGlobeEurope, FaLinkedin, FaTwitter } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./footer.scss";

const Footer = () => {
  return (
    <footer className="myFooter">
      <div className="footer-content">
        <h2 className="footer-logo"> SHELFTRACK</h2>
        <div className="footer-rights">
          Catalink © {new Date().getFullYear()} All rights reserved.
        </div>
        <div className="footer-social">
          <a href="https://catalink.eu" target="_blank" aria-label="Website">
            <FaGlobeEurope />
          </a>
          <a
            href="https://www.linkedin.com/company/catalink-ltd"
            target="_blank"
            aria-label="LinkedIn"
          >
            <FaLinkedin />
          </a>
          <a
            href="https://twitter.com/catalink_eu"
            target="_blank"
            aria-label="LinkedIn"
          >
            <FaXTwitter />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
