import "./table.scss";
import uuid from "react-uuid";
import { Grid } from "@mui/material";
import EditUserModal from "../Modal/User/EditUserModal";
import CreateUserModal from "../Modal/User/CreateUserModal";
import { useState } from "react";
import DeleteUserModal from "../Modal/User/DeleteUserModal";

const TableUsers = ({
  title,
  users,
  tableData,
  updatedData,
  newlyAdded,
  deletedUser,
}) => {
  const tableTh = tableData.tableTh;
  const [search, setSearch] = useState("");

  return (
    <>
      <div className="myTableContainer">
        <div className="tableHeader">
          <p className="tableTitle">{title}</p>
          <div className="tableSearchWrapper">
            <input
              className="tableInput"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <CreateUserModal newlyAddedUser={newlyAdded} />
          </div>
        </div>
        <div className="tableSection">
          <table className="tableWrap">
            <thead className="tableHead">
              <tr className="tableTr">
                {tableTh.map((title) => (
                  <th className="tableTh" key={uuid()}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users
                .filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : (item.name && item.name.toLowerCase().includes(search)) ||
                        (item.username &&
                          item.username.toLowerCase().includes(search)) ||
                        (item.email &&
                          item.email.toLowerCase().includes(search)) ||
                        (item.telephone &&
                          item.telephone.toLowerCase().includes(search));
                })
                .map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.name}</td>
                    <td>{entry.username}</td>
                    <td>{entry.email}</td>
                    <td>{entry.telephone}</td>
                    <td>
                      <Grid
                        className="myCustomGrid"
                        container
                        spacing={0.5}
                        justifyContent="flex-end"
                      >
                        <Grid item>
                          <EditUserModal
                            titleText={"Edit User"}
                            btnText={"Update"}
                            formData={entry}
                            updatedData={updatedData}
                          />
                        </Grid>
                        <Grid item>
                          <DeleteUserModal
                            username={entry.username}
                            removeDeletedUser={deletedUser}
                          />
                        </Grid>
                      </Grid>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableUsers;
