import { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import {
  planogramShelves,
  croppedImages,
  placeholders,
  img_filenames,
  getPlanoImg,
  createPlanoImg,
  uploadNewImg,
  del_placeholder,
  deleteACroppedImg,
  updateCroppedImgs,
} from "../services/api/planogramService";

export const usePlanogram = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { getItem } = useLocalStorage();

  const getPlanogramShelves = async (sectionID) => {
    setError(false);

    try {
      const response = await planogramShelves(getItem("token"), sectionID);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const getCroppedImages = async (sectionID) => {
    setError(false);

    try {
      const response = await croppedImages(getItem("token"), sectionID);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const deleteCroppedImg = async (id) => {
    setError(false);
    try {
      const resposne = await deleteACroppedImg(getItem("token"), id);
      return resposne.data;
    } catch (error) {
      setError(true);
    }
  };

  const updateCroppedImgList = async (imgList) => {
    setError(false);
    try {
      const resposne = await updateCroppedImgs(getItem("token"), imgList);
      return resposne.data;
    } catch (error) {
      setError(true);
    }
  };

  const getPlaceholders = async (sectionID) => {
    setError(false);

    try {
      const response = await placeholders(getItem("token"), sectionID);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const getFilenames = async (sectionID) => {
    setError(false);

    try {
      const response = await img_filenames(getItem("token"), sectionID);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const createPlanogramImages = async (data) => {
    setError(false);

    try {
      const response = await createPlanoImg(getItem("token"), data);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const getPlanogramImages = async (sectionID) => {
    setError(false);

    try {
      const response = await getPlanoImg(getItem("token"), sectionID);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const uploadImg = async (storeId, file) => {
    setError(false);
    try {
      const response = await uploadNewImg(getItem("token"), storeId, file);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const deletePlaceholder = async (id) => {
    setError(false);
    try {
      const response = await del_placeholder(getItem("token"), id);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  return {
    getPlanogramShelves,
    getCroppedImages,
    getPlaceholders,
    getFilenames,
    createPlanogramImages,
    getPlanogramImages,
    uploadImg,
    deletePlaceholder,
    deleteCroppedImg,
    updateCroppedImgList,
  };
};
