import { GridLoader } from "react-spinners";
import "./spinner.scss";

const Spinner = () => {
  return (
    <div className="container">
      <GridLoader color="#ff4e16" size={50} aria-label="Loading Spinner" />;
    </div>
  );
};

export default Spinner;
