import * as React from "react";
import { useState } from "react";
import "../modal.scss";
import {
  Modal,
  Form,
  Spinner,
  Button,
  Alert,
  ListGroup,
} from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import uuid from "react-uuid";
import MasterDropdown from "../MasterDropdown";

function EditProductModal({
  prodIndex,
  products,
  getProductInfo,
  previewImage,
  btnText,
  onProductUpdate,
}) {
  const [show, setShow] = useState(false);
  const [spinnerOn, setSpinnerOn] = useState(false);
  const [alert, setAlert] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState("");
  const [selectedProductName, setSelectedProductName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedProductFromMaster, setSelectedProductFromMaster] =
    useState(null);

  const handleSelect = (selectedProductName, selectedProductID, category) => {
    setSelectedProductId(selectedProductID);
    setSelectedProductName(selectedProductName);
    setSelectedCategory(category);
  };

  const handleUpdate = (e) => {
    e.preventDefault();

    // Calling the callback to update the state in TableProducts.jsx
    const updatedProd = products;
    updatedProd.ir_matches = {
      0: {
        PRODUCT_NAME: selectedProductName,
        IR_distance: "-",
        Similarity: 100,
        PRODUCT_ID: selectedProductId,
      },
    };
    updatedProd.ocr_matches = {
      0: {
        PRODUCT_NAME: selectedProductName,
        PRODUCT_ID: selectedProductId,
      },
    };
    onProductUpdate(updatedProd, prodIndex);
    setShow(false);
  };

  const getModalTitle = () => {
    return `Edit Product:  ${selectedProductName}`;
    // if (selectedProductCategory === "IR" || selectedProductCategory === "OCR") {
    //   return `Edit Product: - ${selectedProductCategory} - ${selectedProductName}`;
    // } else if (selectedProductCategory === "Dropdown") {
    //   return `Edit Product: - ${selectedProductCategory} - ${selectedProductName}`;
    // } else {
    //   return "Edit Product: - No Product Selected -";
    // }
  };

  const RenderIRRow = ({ index, onSelect }) => {
    const handleItemClick = () => {
      const selectedProduct = products.ir_matches[`${index}`]["PRODUCT_NAME"];
      const selectedProductID = products.ir_matches[`${index}`]["PRODUCT_ID"];
      onSelect(selectedProduct, selectedProductID, "IR");
    };

    return (
      <ListGroup.Item
        className={`listItem ${
          selectedProductId === products.ir_matches[`${index}`]["PRODUCT_ID"] &&
          selectedCategory === "IR"
            ? "selected"
            : ""
        }`}
        key={index}
        action
        onClick={handleItemClick}
      >
        {Object.keys(products.ir_matches).length > 0 ? (
          <div>
            {products.ir_matches[`${index}`]["PRODUCT_NAME"]}
            <br />
            <span className="confidence">
              <p>
                Confidence: {products.ir_matches[`${index}`]["Similarity"]}%
              </p>
            </span>
          </div>
        ) : (
          <p>No IR detected</p>
        )}
      </ListGroup.Item>
    );
  };

  const RenderOCRRow = ({ index, onSelect }) => {
    const handleItemClick = () => {
      const selectedProduct = products.ocr_matches[`${index}`]["PRODUCT_NAME"];
      const selectedProductID = products.ocr_matches[`${index}`]["PRODUCT_ID"];
      onSelect(selectedProduct, selectedProductID, "OCR");
    };

    return (
      <ListGroup.Item
        className={`listItem ${
          selectedProductId ===
            products.ocr_matches[`${index}`]["PRODUCT_ID"] &&
          selectedCategory === "OCR"
            ? "selected"
            : ""
        }`}
        key={index}
        action
        onClick={handleItemClick}
      >
        {Object.keys(products.ocr_matches).length > 0 ? (
          <div>
            {products.ocr_matches[`${index}`]["PRODUCT_NAME"]}
            <br />
            <span className="confidence">
              <p style={{ marginTop: "10px" }}>
                String Similarity:{" "}
                {products.ocr_matches[`${index}`]["Similarity"]}%, Image exist
                on master:{" "}
                {products.ocr_matches[`${index}`]["hasMasterImage"]
                  ? "True"
                  : "False"}
              </p>
            </span>
          </div>
        ) : (
          <p>No OCR detected</p>
        )}
      </ListGroup.Item>
    );
  };

  const handleProductChangeFromMaster = (prod) => {
    handleSelect(prod["name"], prod["id"], "master");
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  return (
    <>
      <Tooltip
        title="Edit Product"
        placement="top"
        arrow
        leaveDelay={20}
        //followCursor={true}
        //visible={!show}
      >
        <button action="edit" onClick={handleShow}>
          <FaRegEdit />
        </button>
      </Tooltip>

      <Modal
        id="editProductsModal"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {getModalTitle()} </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            *User can choose one product, either from visual matches (IR),
            textual matches (OCR), or manual search (masterfile).
          </p>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <div id="editProductsWrapper">
            <Form id="editUserModal" onSubmit={handleUpdate}>
              <Form.Group className="mb-3 bestSuggested" controlId={uuid()}>
                <span id="productTitle">Product Name (best match):</span> <br />
                <span id="productTitleInfo">{getProductInfo}</span>
                <div className="thumbnailCell formElement">
                  <img
                    className="thumbnail"
                    id="productPreview"
                    src={previewImage}
                    alt={`Thumbnail for ${getProductInfo}`}
                    height={"500px"}
                    width={"500px"}
                  />
                </div>
                {/* 
                <div id="masterDropdown formElement">
                  <MasterDropdown />
                </div>
                */}
              </Form.Group>

              <Form.Group id="cvLists">
                <div className="cvList">
                  <ListGroup className="editProductSub">
                    <ListGroup.Item
                      className="list-group-item list-group-item-info"
                      action
                    >
                      Visual Matches (IR)
                    </ListGroup.Item>
                    {/* RenderIRRow for IR products */}
                    {Array.from(
                      { length: Object.keys(products.ir_matches).length },
                      (_, index) => (
                        <RenderIRRow
                          key={index}
                          index={index}
                          onSelect={handleSelect}
                        />
                      )
                    )}
                  </ListGroup>
                </div>

                <div className="cvList">
                  <ListGroup className="editProductSub">
                    <ListGroup.Item
                      className="list-group-item list-group-item-success"
                      action
                    >
                      Textual Matches (OCR)
                    </ListGroup.Item>
                    {/* RenderOCRRow for OCR products */}
                    {Array.from(
                      { length: Object.keys(products.ocr_matches).length },
                      (_, index) => (
                        <RenderOCRRow
                          key={index}
                          index={index}
                          onSelect={handleSelect}
                        />
                      )
                    )}
                  </ListGroup>
                </div>

                <div className="cvList">
                  <ListGroup className="editProductSub">
                    <ListGroup.Item
                      className="list-group-item list-group-item-warning"
                      action={false}
                    >
                      Manual Search (masterfile)
                    </ListGroup.Item>
                    {/* MasterDropdown for manual search */}
                    <MasterDropdown
                      onProductChange={handleProductChangeFromMaster}
                    />
                  </ListGroup>
                </div>
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {spinnerOn ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" form="editUserModal" type="submit">
                {btnText}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditProductModal;
