import './login.scss'

import React, { useState, useEffect } from 'react'
import shelftrackLogo from '../../assets/logos/shelftrackLogo.svg'
import  Snackbar  from '@mui/material/Snackbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { useLogin } from '../../hooks/useLogin';

const Login = () => {
  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [pwdVisible, setPwdVisible] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const { login, error, isLoading } = useLogin()

  const togglePwd = () => setPwdVisible(!pwdVisible)

  const handleAlert = (error) => {
    if (error) {
        setSnackbarOpen(true); // Open Snackbar when an error occurs
      } else {
        setSnackbarOpen(false); // Close Snackbar when there is no error
      }
  }

  useEffect(() => {
    // Call handleAlert with the error value when it changes
    handleAlert(error);
  }, [error]);

  const handleLogin = async (e) => {
    
    // The default behavior when submitting a form is to refresh the page
    // With this we prevent this from happening
    e.preventDefault()

    await login(username, password)
  }

  return (
    <div className="login">
      <div className='loginBox'>
        <div className='left'>
            <div className='logo-container'>
                <img src={shelftrackLogo} alt=''/>
            </div>
        </div>
        <div className='right'>
          <div className='contact'>
            <form action="" onSubmit={handleLogin}>
              <h2>SIGN IN</h2>
              <p>Please sign in to see your dashboard</p>
              <input type='text' name='username' placeholder='Username' autoComplete='off' value={username} onChange={(e) => setUsername(e.target.value)}/>
              <input type={pwdVisible?'text':'password'} name='password' placeholder='Password' autoComplete='off' value={password} onChange={(e) => setPassword(e.target.value)}/>
              <span className='togglePassword'>
                <FontAwesomeIcon icon={pwdVisible?faEye:faEyeSlash} onClick={togglePwd}/>
              </span>
              <button className='submitBtn' type='submit' disabled={isLoading}>Login</button>
            </form>
            <Snackbar
                anchorOrigin={{vertical:'bottom', horizontal:'right'}}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleAlert}
                message="Authentication Failed. Please try again."
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
