import { createContext, useEffect, useReducer } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { verifyToken } from "../services/api/authService";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
  });

  const { getItem } = useLocalStorage();

  useEffect(() => {
    const token = getItem("token");

    const verifyTokenAndDispatch = async () => {
      try {
        if (token) {
          const user = await verifyToken(token);
          dispatch({ type: "LOGIN", payload: user });
        } else {
          dispatch({ type: "LOGOUT" });
        }
      } catch (error) {
        // Handle token verification error, e.g., token expired
        dispatch({ type: "LOGOUT" });
      }
    };

    // if (token) {
    //   dispatch({ type: "LOGIN", payload: token });
    // }
    verifyTokenAndDispatch();
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
