import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import uuid from "react-uuid";

//const BASE_URL =process.env.BASE_URL
const BASE_URL='http://148.251.51.182:6567';
const MasterDropdown = ({ onProductChange }) => {
  const [productNames, setProductNames] = useState([]);
  const [loading, setLoading] = useState(true);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 1000,
  });

  useEffect(() => {
    const fetchProductNames = async () => {
      try {
        const response = await fetch(
          //"http://shelftrack.catalink.eu:2005/products/product-names",
          `${BASE_URL}/products/product-names`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch product names");
        }

        const data = await response.json();
        //console.log("data", data);

        if (Array.isArray(data)) {
          setProductNames(data);
        } else if (typeof data === "object" && data.name) {
          // If it's a single object, create an array with that object
          setProductNames([data]);
        } else {
          console.error(
            "Fetched data is not an array or object with name property:",
            data
          );
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching product names:", error);
        setLoading(false);
      }
    };

    fetchProductNames();
  }, []);

  const handleProductSelect = (newValue) => {
    if (newValue) {
      onProductChange(newValue);
    } // Pass the selected product to the parent component
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={productNames}
      loading={loading}
      getOptionLabel={(option) => option?.name || ""}
      style={{ width: "100%" }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label="Search for products" variant="outlined" />
      )}
      onChange={(e, value) => handleProductSelect(value)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
    />
  );
};

export default MasterDropdown;
