import { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import {
  getStores,
  updateStore,
  createStore,
  getGondolas,
  getSections,
  getShelves,
  sales,
  config,
  cvStart,
} from "../services/api/storesService";

export const useStores = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getItem } = useLocalStorage();

  const getAllStores = async () => {
    setError(false);

    try {
      const response = await getStores(getItem("token"));
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const updateStores = async (newStoreData) => {
    setError(false);

    try {
      const response = await updateStore(getItem("token"), newStoreData);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const createNewStore = async (storeData) => {
    setError(false);
    try {
      const response = await createStore(getItem("token"), storeData);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const getAllGondolas = async (store_id) => {
    try {
      const response = await getGondolas(getItem("token"), store_id);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const getAllSections = async (gondola_id) => {
    try {
      const response = await getSections(getItem("token"), gondola_id);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const getAllShelves = async (section_id) => {
    try {
      const response = await getShelves(getItem("token"), section_id);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const uploadSales = async (file) => {
    try {
      const response = await sales(getItem("token"), file);
      return response;
    } catch (error) {
      setError(true);
    }
  };

  const getStoreConfig = async (store_id) => {
    try {
      const response = await config(getItem("token"), store_id);
      return response;
    } catch (error) {
      setError(true);
    }
  };

  const start_cv = async (store_id) => {
    try {
      const response = await cvStart(store_id);
      return response;
    } catch (error) {
      setError(true);
    }
  };

  return {
    getAllStores,
    updateStores,
    createNewStore,
    getAllGondolas,
    getAllSections,
    getAllShelves,
    uploadSales,
    getStoreConfig,
    start_cv,
    isLoading,
    error,
  };
};
