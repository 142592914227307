import React, { useState } from "react";
import { Tooltip } from "@mui/material";
import { FaFileUpload, FaUpload } from "react-icons/fa";
import uuid from "react-uuid";
import { Alert, Modal, Button, Spinner } from "react-bootstrap";
import { useStores } from "../../hooks/useStores";

const UploadSalesModal = ({ storeId }) => {
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(null);

  const fileInputRef = React.createRef();
  const { uploadSales, loading } = useStores();

  const handleShow = () => setShow(true);

  const handleClose = () => {
    setAlert(null);
    setShow(false);
  };

  const uploadFile = async () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      // Valid JSON file selected
      const formData = new FormData();
      formData.append("file", file);
      formData.append("storeID", storeId);
      const response = await uploadSales(formData);
      console.log(response);
      if (response == 201) {
        setAlert({
          variant: "success",
          message: "File uploaded successfully.",
        });
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    } else {
      // Invalid file type
      setAlert({
        variant: "danger",
        message: "Please select a valid file.",
      });
    }
  };

  return (
    <>
      <Tooltip
        title="Upload Sales"
        placement="top"
        arrow
        //followCursor={true}
        leaveDelay={20}
      >
        <button action="upload" onClick={handleShow}>
          <FaFileUpload />
        </button>
      </Tooltip>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Sales File</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <FaUpload
                  size={"80px"}
                  color="#ff4e16"
                  onClick={uploadFile}
                  style={{ cursor: "pointer" }}
                />
                <input
                  type="file"
                  accept=".xlsx"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadSalesModal;
