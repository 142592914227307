import React from "react";
import { Rect } from "react-konva";

const Shelf = ({ positionX, positionY, width, height, id }) => {
  return (
    <Rect
      x={positionX}
      y={positionY}
      width={width}
      height={height}
      stroke="black"
      strokeWidth={2}
      draggable={false}
      id={id}
    />
  );
};

export default Shelf;
