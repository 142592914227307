import "./home.scss";

import { useLogout } from "../../hooks/useLogout";
import Layout from "../../layout/Layout/Layout";
import shelftrackLogo from "../../assets/logos/shelftrackLogo.svg";

const Home = () => {
  const { logout } = useLogout();

  const handleLogout = () => {
    logout();
  };

  return (
    <Layout>
      <div className="infoContainer">
        <div className="infoRow">
          <div className="infoRow-column1">
            <div className="textWrapper">
              <p className="topline">ShelfTrack</p>
              <h1>A premium planogram maker</h1>
              <p className="subtitle">
                A planogram application which helps the store owner to better
                organize the products on the shelves of the store.
              </p>
            </div>
          </div>
          <div className="infoRow-column2">
            <img src={shelftrackLogo} alt="logo"></img>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
