import { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { AiFillDelete } from "react-icons/ai";

import { useUsers } from "../../../hooks/useUsers";

function DeleteUserModal({ username, removeDeletedUser }) {
  const [show, setShow] = useState(false);
  const { deleteUsers } = useUsers();
  const [alert, setAlert] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setAlert(null);
  };

  const handleDelete = async () => {
    const response = await deleteUsers(username);
    if (response) {
      setShow(false);
      removeDeletedUser(username);
    } else {
      setAlert({
        variant: "danger",
        message: "Error creating the user. Please try again.",
      });
    }
  };

  return (
    <>
      <Tooltip
        title="Edit Store"
        placement="top"
        arrow
        //followCursor={true}
        leaveDelay={20}
        color="red"
      >
        <button action="delete" onClick={handleShow}>
          <AiFillDelete />
        </button>
      </Tooltip>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          Are you sure you want to delete user {username}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" color="ff2400" onClick={handleDelete}>
            Delete User
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteUserModal;
