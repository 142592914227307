import React from "react";
import { Image } from "react-konva";

class CroppedImage extends React.Component {
  state = {
    image: null,
    isDragging: false,
  };
  componentDidMount() {
    this.loadImage();
  }
  componentDidUpdate(oldProps) {
    if (oldProps.src !== this.props.src) {
      this.loadImage();
    }
  }
  componentWillUnmount() {
    this.image.removeEventListener("load", this.handleLoad);
  }
  loadImage() {
    // save to "this" to remove "load" handler on unmount
    this.image = new window.Image();
    this.image.src = this.props.src;
    this.image.addEventListener("load", this.handleLoad);
  }
  handleLoad = () => {
    // after setState react-konva will update canvas and redraw the layer
    // because "image" property is changed
    this.setState({
      image: this.image,
    });
    // if you keep same image object during source updates
    // you will have to update layer manually:
    // this.imageNode.getLayer().batchDraw();
  };
  render() {
    return (
      <Image
        x={this.props.x}
        y={this.props.y}
        width={this.props.width}
        height={this.props.height}
        image={this.state.image}
        draggable={false}
        onDragStart={() => this.setState({ isDragging: false })}
        onDragEnd={(e) =>
          this.setState({
            isDragging: false,
            x: e.target.x(),
            y: e.target.y(),
          })
        }
        stroke={this.props.category === "dermo" ? "red" : null}
        strokeWidth={5}
        ref={(node) => {
          this.imageNode = node;
        }}
        onMouseOver={(evt) => {
          var shape = evt.target;
          shape.scaleX(2);
          shape.scaleY(2);
        }}
        onMouseOut={(evt) => {
          var shape = evt.target;
          shape.scaleX(1);
          shape.scaleY(1);
        }}
      />
    );
  }
}

export default CroppedImage;
