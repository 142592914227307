import React, { useEffect, useState, useRef } from "react";
import { Layer, Rect, Stage } from "react-konva";
import "./planogramComponent.scss";
import Shelf from "../Shelf/Shelf";
import uuid from "react-uuid";
import { usePlanogram } from "../../hooks/usePlanogram";
import { Alert } from "react-bootstrap";
import CroppedImage from "./CroppedImage";
//import EditPlaceholdersModal from "../Modal/EditPlaceholdersModal";

const PlanogramComponent = ({ selectedSec }) => {
  const stageRef = useRef();
  const [shelvesList, setShelvesList] = useState([]);
  const [croppedImagesList, setCroppedImagesList] = useState([]);
  const [placeholdersList, setPlaceholdersList] = useState([]);
  const [imgFilenamesList, setImgFilenameList] = useState([]);
  const [alert, setAlert] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);

  const [selectedPH, setSelectedPH] = useState(null);
  const [editPHModal, setEditPHModal] = useState(false);
  const [store, setStore] = useState(null);
  const [gond, setGond] = useState(null);

  const {
    getPlanogramShelves,
    getPlanogramImages,
    //getPlaceholders,
    createPlanogramImages,
    deletePlaceholder,
    getFilenames,
  } = usePlanogram();

  useEffect(() => {
    if (selectedSec !== null) {
      const fetchShelves = async () => {
        const shelves = await getPlanogramShelves(selectedSec);
        setShelvesList(shelves);
      };

      const fetchCroppedImages = async () => {
        const images = await getPlanogramImages(selectedSec);
        if (images.length === 0) {
          setAlert({
            variant: "info",
            message:
              "There are no images for this section. Please go to Product and confirm the proposed list of products for this section.",
          });
        }
        setCroppedImagesList(images);
      };

      // const fetchPlaceholders = async () => {
      //   const images = await getPlaceholders(selectedSec);

      //   setPlaceholdersList(images);
      // };

      const fetchImageFilenames = async () => {
        const data = await getFilenames(selectedSec);
        setStore(data["storeId"]);
        setGond(data["gondola"]);
        setImgFilenameList(data["filenames"]);
      };

      fetchShelves();
      fetchCroppedImages();
      // fetchPlaceholders();
      fetchImageFilenames();

      setAlert(null);
    }
  }, [selectedSec]);

  const handlePlaceholderClicked = (placeholder) => {
    setSelectedPH(placeholder);
    setEditPHModal(true);
  };

  const handleClosePHModal = () => {
    setEditPHModal(false);
  };

  const handleUpdatedLists = async (updatedCroppedImages, id) => {
    // add new croppedImages to the list
    setCroppedImagesList((prevCroppedImagesList) => [
      ...prevCroppedImagesList,
      ...updatedCroppedImages,
    ]);

    // delete placeholder
    setPlaceholdersList((prevPlaceholdersList) =>
      prevPlaceholdersList.filter((ph) => ph.id !== id)
    );
    setEditPHModal(false);

    // store new images in the db
    await createPlanogramImages(updatedCroppedImages);
    // delete placholder from db
    await deletePlaceholder(id);
  };

  const handleWheel = (e) => {
    e.evt.preventDefault();

    const scaleBy = 1.05;
    const oldScale = stageRef.current.scaleX();

    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    setZoomLevel(newScale);
  };

  return (
    <div className="planogramWrap">
      {alert && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert(null)}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <Stage
        ref={stageRef}
        height={2000}
        width={window.innerWidth}
        scaleX={zoomLevel}
        scaleY={zoomLevel}
        onWheel={handleWheel}
      >
        {shelvesList && (
          <Layer>
            {shelvesList.map((shelf) => (
              <Shelf
                key={uuid()}
                positionX={shelf.positionX}
                positionY={shelf.positionY}
                width={shelf.width}
                height={shelf.height}
                id={shelf.id}
              />
            ))}
          </Layer>
        )}
        {croppedImagesList && (
          <Layer>
            {croppedImagesList.map((img) => (
              <CroppedImage
                crossOrigin="null"
                key={uuid()}
                src={`/media/${img.img_path}`}
                x={img.positionX}
                y={img.positionY}
                width={img.width}
                height={img.height}
                category={"dermox"}
              />
            ))}
          </Layer>
        )}
        {/* {placeholdersList && (
          <Layer>
            {placeholdersList.map((ph) => (
              <Rect
                key={uuid()}
                x={ph.positionX}
                y={ph.positionY}
                width={ph.width}
                height={ph.height}
                stroke="red"
                strokeWidth={2}
                draggable={false}
                //onClick={() => handlePlaceholderClicked(ph)}
              />
            ))}
          </Layer>
        )} */}
      </Stage>
      {/* <EditPlaceholdersModal
        show={editPHModal}
        placeholder={selectedPH}
        handleClose={handleClosePHModal}
        handleNewData={handleUpdatedLists}
        imgFilenamesList={imgFilenamesList}
        store={store}
        gond={gond}
      /> */}
    </div>
  );
};

export default PlanogramComponent;
