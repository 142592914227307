import React, { useState, useEffect } from "react";
import "./stores.scss";
import Layout from "../../layout/Layout/Layout";
import Table from "../../components/Tables/TableStores";
import { useStores } from "../../hooks/useStores";
import Spinner from "../../components/Spinner/Spinner";

const Stores = () => {
  const [stores, setStores] = useState([]);
  const [isCvRunning, setIsCvRunning] = useState(false);
  const { getAllStores, isLoading } = useStores();

  const tableStores = {
    title: "Store Details",
    headerBtn: "Add new store",
    tableTh: [
      "ID",
      "Physical Address",
      "Store Owner",
      "Name",
      "Configured",
      "Processed With CV",
      "CV run on",
      "Actions",
    ],
  };

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const data = await getAllStores();
        const sortedList = data.sort((a, b) =>
          a.store_id.localeCompare(b.store_id)
        );

        setStores(sortedList);

        const isAnyCvRunning = sortedList.some(
          (store) => store.last_cv_run === "ongoing"
        );
        setIsCvRunning(isAnyCvRunning);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };
    fetchStores();
  });

  const updatedStoreData = (updatedData) => {
    const index = stores.findIndex(
      (store) => store.store_id === updatedData.store_id
    );

    if (index !== -1) {
      const updatedStores = [...stores];
      updatedStores[index] = updatedData;

      setStores(updatedStores);

      // Check if any store has "ongoing" CV process
      const isAnyCvRunning = updatedStores.some(
        (store) => store.last_cv_run === "ongoing"
      );
      setIsCvRunning(isAnyCvRunning);
    }
  };

  const newlyAddedStores = (newStore) => {
    setStores((prevStores) => [...prevStores, newStore]);
  };

  return (
    <Layout>
      {isLoading ? (
        <Spinner />
      ) : (
        <Table
          title={"Stores"}
          stores={stores}
          tableData={tableStores}
          updatedData={updatedStoreData}
          isCvRunning={isCvRunning}
          setIsCvRunning={setIsCvRunning}
          newlyAdded={newlyAddedStores}
        />
      )}
    </Layout>
  );
};

export default Stores;
