import { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import {
  getUsers,
  updateUser,
  createUser,
  getUnames,
  deleteUser,
} from "../services/api/usersService";

export const useUsers = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getItem } = useLocalStorage();

  const getAllUsers = async () => {
    setError(false);

    try {
      const response = await getUsers(getItem("token"));
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const updateUsers = async (newUserData) => {
    setError(false);

    try {
      const response = await updateUser(getItem("token"), newUserData);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const createNewUser = async (userData) => {
    setError(false);

    try {
      const response = await createUser(getItem("token"), userData);
      return response.data;
    } catch (error) {
      setError(true);
    }
  };

  const getUsernames = async () => {
    setError(false);

    try {
      const response = await getUnames(getItem("token"));
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const deleteUsers = async (uname) => {
    setError(false);

    try {
      const response = await deleteUser(getItem("token"), uname);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  return {
    getAllUsers,
    updateUsers,
    createNewUser,
    getUsernames,
    deleteUsers,
    error,
    isLoading,
  };
};
