import axios from "axios";
import * as Constants from "../../utils/constants";

export const uploadMaster = async (token, data) => {
  try {
    const response = await axios.post(Constants.MASTER_FILE, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.status;
  } catch (error) {
    return error.message;
  }
};

export const getLastTimeUpdated = async (token) => {
  try {
    const response = await axios.get(Constants.LAST_UPDATED, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    return error.message;
  }
};

export const addNewProd = async (token, newProd) => {
  try {
    const response = await axios.post(
      Constants.ADD_NEW_PROD,
      {
        product_id: parseInt(newProd.product_id),
        product_name: newProd.product_name,
        product_details: newProd,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};
