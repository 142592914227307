import React, { useState, useEffect } from "react";
import Layout from "../../layout/Layout/Layout";
import "./users.scss";
import { Spinner } from "react-bootstrap";
import TableUsers from "../../components/Tables/TableUsers";
import { useUsers } from "../../hooks/useUsers";
import { deleteUser } from "../../services/api/usersService";

const Users = () => {
  const [users, setUsers] = useState([]);
  const { getAllUsers, isLoading, error } = useUsers();

  const tableUsers = {
    title: "User Details",
    headerBtn: "Add new User",
    tableTh: ["Name", "Username", "Email", "Phone Number", "Actions"],
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const data = await getAllUsers();
        const sortedList = data.sort((a, b) => a.name.localeCompare(b.name));
        setUsers(sortedList);
      } catch (error) {
        console.error("Error while fetching users:", error);
      }
    };
    fetchUsers();
  }, []);

  const updatedUserData = (updatedData) => {
    const index = users.findIndex(
      (user) => user.username === updatedData.username
    );

    if (index !== -1) {
      const updatedUsers = [...users];
      updatedUsers[index] = updatedData;

      setUsers(updatedUsers);
    }
  };

  const newlyAdded = (newUserAdded) => {
    setUsers((prevUsers) => [...prevUsers, newUserAdded]);
  };

  const removeDeletedUser = async (uname) => {
    setUsers((prevUsers) =>
      prevUsers.filter((user) => user.username !== uname)
    );
  };

  return (
    <Layout>
      <TableUsers
        title={"Users"}
        users={users}
        tableData={tableUsers}
        updatedData={updatedUserData}
        newlyAdded={newlyAdded}
        deletedUser={removeDeletedUser}
      />
    </Layout>
  );
};

export default Users;
