import React, { useEffect, useState } from "react";
import { Alert, Modal, Button, Spinner } from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import { useMasterFile } from "../../hooks/useMasterFile";
import { Typography } from "@mui/material";
import uuid from "react-uuid";

const UploadMasterModal = () => {
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(null);
  const [lastUpdate, setLastUpdate] = useState(null);

  const { uploadNewMasterFile, lastTimeUpdated, loading } = useMasterFile();
  const fileInputRef = React.createRef();

  const handleClose = () => {
    setAlert(null);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const uploadFile = async () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/json") {
      // Valid JSON file selected
      const formData = new FormData();
      formData.append("filename", file.name);
      formData.append("json_data", file);
      const response = await uploadNewMasterFile(formData);
      if (response === 201) {
        setAlert({
          variant: "success",
          message: "File uploaded successfully.",
        });
        setTimeout(() => {
          handleClose();
        }, 3000);
      }
    } else {
      // Invalid file type
      setAlert({
        variant: "danger",
        message: "Please select a valid JSON file.",
      });
    }
  };

  useEffect(() => {
    const fetchLastUpdatedTime = async () => {
      try {
        const response = await lastTimeUpdated();
        const date = new Date(response);
        const formattedDate = `${date.getDate()}-${date.toLocaleString(
          "default",
          { month: "short" }
        )}-${date.getFullYear()} ${date.getHours()}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")} ${date.getHours() >= 12 ? "PM" : "AM"}`;
        setLastUpdate(formattedDate);
      } catch (error) {
        console.log(error);
      }
    };

    fetchLastUpdatedTime();
  }, []);

  return (
    <>
      <Button
        style={{
          marginRight: "10px",
          backgroundColor: "transparent",
          border: "1px solid green",
          color: "white",
        }}
        onClick={handleShow}
        key={uuid()}
      >
        {"Upload New Master File"}
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload New Master File</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}

          <Typography marginBottom={5}>
            Current master file uploaded on: {lastUpdate}
          </Typography>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div>
                <FaUpload
                  size={"80px"}
                  color="#ff4e16"
                  onClick={uploadFile}
                  style={{ cursor: "pointer" }}
                />
                <input
                  type="file"
                  accept=".json"
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  onChange={handleFileChange}
                />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UploadMasterModal;
