import React from "react";
import Navbar from "../Navbar/Navbar";

import "./layout.scss";
import Footer from "../Footer/Footer";

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <div className="myLayoutContainer">{children}</div>
      <Footer />
    </>
  );
};

export default Layout;
