import { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { GiConfirmed } from "react-icons/gi";
import { usePlanogram } from "../../../hooks/usePlanogram";

function ConfirmProductModal({ product, removeConfirmedProd }) {
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(null);

  const { createPlanogramImages } = usePlanogram();

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setAlert(null);
  };

  const handleSubmit = async () => {
    const newImg = {
      section_id: product.section_id,
      store_id: product.store_id,
      gondola_id: product.gondola_id,
      width: product.width,
      height: product.height,
      positionX: product.positionX,
      positionY: product.positionY,
      positionShelf: product.positionShelf,
      shelf: product.shelf,
      product_id:
        product.type === "image" ? product.ir_matches[0].PRODUCT_ID : " ",
      product_name:
        product.type === "image"
          ? product.ir_matches[0].PRODUCT_NAME
          : "Placeholder",
      img_path: product.img_path,
    };

    await createPlanogramImages(newImg);
    removeConfirmedProd(product.id);
    setShow(false);
  };

  return (
    <>
      <Tooltip
        title="Confirm Product/Placeholder"
        placement="top"
        arrow
        //followCursor={true}
        leaveDelay={20}
        color="green"
      >
        <button action="upload" onClick={handleShow}>
          <GiConfirmed />
        </button>
      </Tooltip>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Product/Placeholder Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          You are about to confirm{" "}
          {product.type === "image"
            ? product.ir_matches["0"]["PRODUCT_NAME"]
            : "a Placeholder"}
          .
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" color="ff2400" onClick={handleSubmit}>
            {product.type === "image"
              ? "Confirm Product"
              : "Confirm Placeholder"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmProductModal;
