import React, { useEffect, useState } from "react";

import "./navbar.scss";
import { BiMenuAltRight } from "react-icons/bi";
import { AiOutlineClose } from "react-icons/ai";

import { Link } from "react-router-dom";

import { useLogout } from "../../hooks/useLogout";
import UploadMasterModal from "../../components/Modal/UploadMasterModal";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [size, setSize] = useState({
    width: undefined,
    height: undefined,
  });

  const menuToggleHandler = () => {
    setMenuOpen((p) => !p);
  };

  const { logout } = useLogout();

  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (size.width > 768 && menuOpen) {
      setMenuOpen(false);
    }
  }, [size.width, menuOpen]);

  const handleLogout = () => {
    logout();
  };

  const uploadMasterModal = () => {};

  return (
    <header className="myNavbar">
      <div className="navbar-content">
        <h2 className="navbar-logo">SHELFTRACK</h2>
        <nav className={`navbar-nav ${menuOpen ? "isMenu" : ""}`}>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/stores">Stores</Link>
            </li>
            <li>
              <Link to="/users">Users</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/planogram">Planogram</Link>
            </li>
            <li>
              <Link to="/exports">Exports</Link>
            </li>
          </ul>
          <UploadMasterModal />
          <button onClick={handleLogout}>Logout</button>
        </nav>
        <div className="navbar-toggle">
          {!menuOpen ? (
            <BiMenuAltRight onClick={menuToggleHandler} />
          ) : (
            <AiOutlineClose onClick={menuToggleHandler} />
          )}
        </div>
      </div>
    </header>
  );
};

export default Navbar;
