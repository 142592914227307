import { useAuthContext } from "./useAuthContext"
import { useLocalStorage } from "./useLocalStorage"


export const useLogout = () => {
    const { dispatch } = useAuthContext()
    const { removeItem } = useLocalStorage()


    const logout = () => {
        removeItem('token')
        dispatch({type: 'LOGOUT'})
    }

    return { logout }
}