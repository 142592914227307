import { useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { Tooltip } from "@mui/material";
import { AiFillDelete } from "react-icons/ai";

function DeleteProductModal({ productName, removeDeletedProduct, replaceImg }) {
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setAlert(null);
  };

  const handleDelete = async () => {
    // Assuming a successful delete for testing purposes
    setShow(false);
    removeDeletedProduct();
  };

  const handleReplace = async () => {
    setShow(false);
    replaceImg();
  };

  return (
    <>
      <Tooltip
        title="Delete Product/Placeholder"
        placement="top"
        arrow
        //followCursor={true}
        leaveDelay={20}
        color="red"
      >
        <button action="delete" onClick={handleShow}>
          <AiFillDelete />
        </button>
      </Tooltip>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Product / Placeholder</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          Are you sure you want to delete the {productName}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-info" onClick={handleClose}>
            Close
          </Button>
          {productName === "Placeholder" ? (
            <></>
          ) : (
            <Button
              style={{
                color: "black",
                backgroundColor: "transparent",
                border: "1px solid #ff2400",
              }}
              onClick={handleReplace}
            >
              {" "}
              Replace with Placeholder
            </Button>
          )}
          <Button variant="danger" color="ff2400" onClick={handleDelete}>
            {productName === "Placeholder"
              ? "Delete Placeholder"
              : "Delete Product"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteProductModal;
