import { useEffect, useState } from "react";
import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";
import uuid from "react-uuid";
import { useStores } from "../../../hooks/useStores";
import { useUsers } from "../../../hooks/useUsers";

function CreateStoreModal({ newlyAddedStore }) {
  const [show, setShow] = useState(false);
  const [uname, setUname] = useState("");
  const [storeId, setStoreId] = useState("");
  const [addr, setAddr] = useState("");
  const [name, setName] = useState("");

  const [owUnames, setOwUnames] = useState(["Select Owner"]);

  const [spinnerOn, setSpinnerOn] = useState(false);
  const [alert, setAlert] = useState(null);

  const { createNewStore } = useStores();

  const { getUsernames } = useUsers();

  useEffect(() => {
    const fetchUsernames = async () => {
      try {
        const data = await getUsernames();
        setOwUnames((prevUnames) => [...new Set([...prevUnames, ...data])]);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };
    fetchUsernames();
  }, []);

  const handleClose = () => {
    setUname("");
    setStoreId("");
    setAddr("");
    setName("");
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (uname === "Select Owner" || uname === "") {
      setAlert({
        variant: "danger",
        message: "Please select an owner.",
      });
    } else {
      setSpinnerOn(true);
      const storeData = {
        store_id: storeId.trim(),
        physical_address: addr.trim(),
        owner_username: uname,
        name: name.trim(),
        configured: false,
        processed_with_cv: false,
      };

      const response = await createNewStore(storeData);
      if (response) {
        setSpinnerOn(false);
        newlyAddedStore(storeData);
        setShow(false);
      } else {
        setSpinnerOn(false);
        setAlert({
          variant: "danger",
          message: "Error creating the store. Please try again.",
        });
      }
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {"Create New Store "}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Store</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Form id="createStoreForm" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Store ID</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Insert the store id"
                onChange={(e) => {
                  setStoreId(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Owner</Form.Label>
              <Form.Select
                required
                placeholder="Select Owner"
                value={uname}
                onChange={(e) => {
                  setUname(e.target.value);
                }}
              >
                {owUnames.map((username) => (
                  <option key={uuid()}>{username}</option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Physical Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Insert the address of the store"
                onChange={(e) => {
                  setAddr(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Store Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Insert the name of the store"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {spinnerOn ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" form="createStoreForm" type="submit">
                {"Create Store"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateStoreModal;
