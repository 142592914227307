import { useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { getSales, getMaster } from "../services/api/exports";

export const useExports = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { getItem } = useLocalStorage();

  const getSalesFile = async (storeId) => {
    setError(false);

    try {
      const response = await getSales(getItem("token"), storeId);
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  const getNewMaster = async () => {
    setError(false);

    try {
      const response = await getMaster(getItem("token"));
      setIsLoading(false);
      return response;
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  return { getSalesFile, getNewMaster, error, isLoading };
};
