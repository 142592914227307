import "./table.scss";
import { GiConfirmed, GiCancel } from "react-icons/gi";
import { FaPlay } from "react-icons/fa";
import { Grid, Tooltip } from "@mui/material";
import EditModal from "../Modal/EditModal";
import CreateStoreModal from "../Modal/Store/CreateStoreModal";
import { useState } from "react";
import { Alert } from "react-bootstrap";
import UploadSalesModal from "../Modal/UploadSalesModal";
import { useStores } from "../../hooks/useStores";

const TableStores = ({
  title,
  stores,
  tableData,
  updatedData,
  newlyAdded,
  isCvRunning,
  setIsCvRunning,
}) => {
  const tableTh = tableData.tableTh;
  const [search, setSearch] = useState("");
  const [alert, setAlert] = useState(null);
  const { start_cv, updateStores } = useStores();

  const handleCVStart = async (storeId) => {
    setIsCvRunning(true);
    await start_cv(storeId);
    setAlert({
      variant: "info",
      message: `CV has started for store: ${storeId}`,
    });

    const timer = setTimeout(() => {
      setIsCvRunning(false);
      setAlert(null);
    }, 3000);

    return () => {
      setIsCvRunning(false);
      clearTimeout(timer); // Clear the timeout if the component unmounts or if setShowAlert(false) is triggered before the timeout
    };
  };

  const handleStartCvClick = async (entry) => {
    const newStoreData = {
      ...entry,
      last_cv_run: "ongoing",
    };

    updateStores(newStoreData); // Update the store data in the database
    // updatedData(newStoreData); // Update the store data in the state
    handleCVStart(entry.store_id);
  };

  return (
    <>
      {alert && (
        <Alert
          variant={alert.variant}
          onClose={() => setAlert(null)}
          dismissible
        >
          {alert.message}
        </Alert>
      )}
      <div className="myTableContainer">
        <div className="tableHeader">
          <p className="tableTitle">{title}</p>
          <div className="tableSearchWrapper">
            <input
              className="tableInput"
              placeholder="Search"
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
            <CreateStoreModal newlyAddedStore={newlyAdded} />
          </div>
        </div>
        <div className="tableSection">
          <table className="tableWrap">
            <thead className="tableHead">
              <tr className="tableTr">
                {tableTh.map((title, index) => (
                  <th className="tableTh" key={index}>
                    {title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {stores
                .filter((item) => {
                  return search.toLowerCase() === ""
                    ? item
                    : item.store_id.toLowerCase().includes(search) ||
                        item.physical_address.toLowerCase().includes(search) ||
                        item.owner_username.toLowerCase().includes(search);
                })
                .map((entry, index) => (
                  <tr key={index}>
                    <td>{entry.store_id}</td>
                    <td>{entry.physical_address}</td>
                    <td>{entry.owner_username}</td>
                    <td>{entry.name}</td>
                    <td>
                      {entry.configured ? (
                        <GiConfirmed fill="green" />
                      ) : (
                        <GiCancel fill="red" />
                      )}
                    </td>
                    <td>
                      {entry.processed_with_cv ? (
                        <GiConfirmed fill="green" />
                      ) : (
                        <GiCancel fill="red" />
                      )}
                    </td>
                    <td>{entry.last_cv_run} </td>
                    <td>
                      <Grid
                        className="myCustomGrid"
                        container
                        spacing={0.5}
                        justifyContent="flex-end"
                      >
                        <Grid item>
                          {/* edit store */}
                          <EditModal
                            titleText={"Edit Store"}
                            btnText={"Update"}
                            formData={entry}
                            updatedData={updatedData}
                          />
                        </Grid>
                        <Grid item>
                          {/* upload sales excel */}
                          <UploadSalesModal storeId={entry.store_id} />
                        </Grid>
                        <Grid item>
                          {/* start cv */}
                          <Tooltip
                            title={isCvRunning ? "CV is running" : "Start CV"}
                            placement="top"
                            arrow
                            //followCursor={true}
                            leaveDelay={20}
                          >
                            <button
                              disabled={isCvRunning}
                              action={isCvRunning ? "cvRuns" : "startCV"}
                              //key={uuid()}
                              onClick={() => handleStartCvClick(entry)}
                            >
                              <FaPlay />
                            </button>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableStores;
