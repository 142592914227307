import React, { useState, useEffect } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditProductModal from "../Modal/Product/EditProductModal";
import DeleteProductModal from "../Modal/Product/DeleteProductModal";
import ConfirmProductModal from "../Modal/Product/ConfirmProductModal";
import uuid from "react-uuid";
import "./table.scss";
import { usePlanogram } from "../../hooks/usePlanogram";
import EditPlaceholdersModal from "../Modal/Placeholders/EditPlaceholdersModal";
import img_placeholder from "../../assets/images/img_placeholder.png";

const TableProducts = ({ imgs, filenames, filenamesMobile, store }) => {
  const [sortedList, setSortedList] = useState([]);
  // mui confirm dialog start
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  // mui confirm dialog end

  const handleProductUpdate = (updatedProd, index) => {
    // Update the state in TableProducts.jsx
    let updatedImgs = [...sortedList];
    updatedImgs[index] = updatedProd;
    setSortedList(updatedImgs);
  };

  const { deleteCroppedImg, updateCroppedImgList, createPlanogramImages } =
    usePlanogram();

  useEffect(() => {
    // Custom sorting function based on "shelves" and "positionShelf"
    const customSort = (a, b) => {
      // First, compare by "shelf"
      if (a.shelf !== b.shelf) {
        return a.shelf - b.shelf;
      }

      // If "shelf" values are equal, compare by "position"
      return a.positionShelf - b.positionShelf;
    };

    // Sort the list using the custom sorting function when props change
    const sorted = [...imgs].sort(customSort);
    setSortedList(sorted);
  }, [imgs]);

  const tableData = {
    tableTh: [
      "Shelf",
      "Position",
      "Product Preview",
      "Suggested Product",
      "Confidence Score",
    ],
  };

  const removeConfirmedProdFromTable = async (prodID) => {
    setSortedList((prevItems) =>
      prevItems.filter((item) => item.id !== prodID)
    );

    //TODO:  error handling

    await deleteCroppedImg(prodID);
  };

  const handleUpdatedListFromPh = async (updatedCroppedImgList, id) => {
    //console.log(updatedCroppedImgList);

    let theUpdatedList = [];

    setSortedList((prevList) => {
      const newList = [...prevList];
      const replaceIndex = sortedList.findIndex((item) => item.id === id);

      if (replaceIndex !== -1) {
        // Remove the item to replace
        newList.splice(replaceIndex, 1, ...updatedCroppedImgList);

        // Update positions of items below the new items
        const shelf = newList[replaceIndex].shelf;

        for (
          let i = replaceIndex + updatedCroppedImgList.length;
          i < newList.length;
          i++
        ) {
          if (newList[i].shelf === shelf) {
            newList[i].positionShelf = newList[i - 1].positionShelf + 1;
          }
        }
      }
      theUpdatedList = newList;
      return newList;
    });

    // update cropped images in db
    await updateCroppedImgList(theUpdatedList);
    await deleteCroppedImg(id);
  };

  const filteredFilenames = (product) => {
    const section = product.section_id;

    const pattern = store + "_";
    const result = section.replace(pattern, "");

    // const parts = section.split("_");
    // parts.shift();
    // const finalString = parts.join("_");
    const filteredImgs = filenamesMobile.filter((image) =>
      image.includes(result)
    );

    return filteredImgs;
  };

  const replaceImage = async (prod) => {
    const new_ph = {
      id: prod.id,
      section_id: prod.section_id,
      store_id: prod.store_id,
      gondola_id: prod.gondola_id,
      width: prod.width,
      height: prod.height,
      positionX: prod.positionX,
      positionY: prod.positionY,
      shelf: prod.shelf,
      positionShelf: prod.positionShelf,
      ir_matches: null,
      ocr_matches: null,
      img_path: null,
      type: "placeholder",
    };

    //update croppedImg to db
    const res = await updateCroppedImgList([new_ph]);
    const updatedList = [...sortedList];
    const index = updatedList.findIndex((product) => product.id === prod.id);
    if (index !== -1) {
      updatedList[index] = new_ph;
      setSortedList(updatedList);
    } else {
      console.log("Prod not found");
    }
  };

  return (
    <>
      <div className="myTableContainer">
        <div className="tableHeader">
          <p className="tableTitle">Products Number: {imgs.length}</p>
          <div className="tableSearchWrapper"></div>
        </div>
        <div className="tableSection">
          <table className="tableWrap">
            <thead className="tableHead">
              <tr className="tableTr">
                {tableData.tableTh.map((title) => (
                  <th className="tableTh" key={uuid()}>
                    {title}
                  </th>
                ))}
                <th className="tableTh">Actions</th>
              </tr>
            </thead>
            <tbody>
              {sortedList.map((product, index) => (
                <tr key={index}>
                  <td>{product.shelf}</td>
                  <td>{product.positionShelf}</td>
                  <td className="thumbnailCell">
                    {product.type === "image" ? (
                      <img
                        className="thumbnail"
                        alt=""
                        id="productPreview"
                        src={`/media/${product.img_path}`}
                        height={"50px"}
                        width={"50px"}
                      />
                    ) : (
                      <img
                        className="thumbnail"
                        alt=""
                        id="productPreview"
                        src={img_placeholder}
                        height={"50px"}
                        width={"50px"}
                      />
                    )}
                  </td>
                  <td>
                    {product.type === "image"
                      ? product.ir_matches["0"]["PRODUCT_NAME"]
                      : "placeholder"}
                  </td>
                  <td
                    style={{
                      color:
                        product.type === "image" &&
                        product.ir_matches["0"]["Similarity"] > 70
                          ? "green"
                          : "inherit",
                      fontWeight:
                        product.type === "image" &&
                        product.ir_matches["0"]["Similarity"] > 70
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {product.type === "image"
                      ? `${product.ir_matches["0"]["Similarity"]}%`
                      : "-"}
                  </td>

                  <td>
                    <Grid
                      className="myCustomGrid"
                      container
                      spacing={0.5}
                      justifyContent="center"
                    >
                      <Grid item>
                        {product.type === "image" ? (
                          <EditProductModal
                            prodIndex={index}
                            products={product}
                            btnText={"Update"}
                            getProductInfo={
                              product.ir_matches["0"]["PRODUCT_NAME"]
                            }
                            previewImage={`/media/${product.img_path}`}
                            onProductUpdate={handleProductUpdate}
                          />
                        ) : (
                          <EditPlaceholdersModal
                            placeholder={product}
                            imgFilenamesList={filteredFilenames(product)}
                            handleUpdatedListFromPh={handleUpdatedListFromPh}
                          />
                        )}
                      </Grid>
                      <Grid item>
                        <DeleteProductModal
                          productName={
                            product.type === "image"
                              ? product.ir_matches["0"]["PRODUCT_NAME"]
                              : "Placeholder"
                          }
                          removeDeletedProduct={async () => {
                            const newList = sortedList.filter(
                              (item, i) => i !== index
                            );

                            await deleteCroppedImg(product.id);

                            setSortedList(newList);
                          }}
                          replaceImg={() => replaceImage(product)}
                        />
                      </Grid>
                      <Grid item>
                        <ConfirmProductModal
                          product={product}
                          removeConfirmedProd={removeConfirmedProdFromTable}
                        />
                      </Grid>
                    </Grid>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="tableFooter">
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Products Confirmed!"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  All {imgs.length} products have been confirmed and saved in
                  the database.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} autoFocus color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            {/* <Button variant="contained" color="success" onClick={handleConfirm}>
              CLICK TO CONFIRM ALL {imgs.length} PRODUCTS
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableProducts;
