import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { useLocalStorage } from "./useLocalStorage";
import { loginUser } from "../services/api/authService";

export const useLogin = () => {
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();
  const { setItem } = useLocalStorage();

  const login = async (username, password) => {
    setIsLoading(true);
    setError(false);

    try {
      const response = await loginUser(username, password);
      const accessToken = response.data.access_token;
      setItem("token", accessToken);
      dispatch({ type: "LOGIN", payload: response.data.access_token });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  return { login, isLoading, error };
};
