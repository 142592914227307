import { useState } from "react";
import { Modal, Form, Spinner, Button, Alert } from "react-bootstrap";
import { FaRegEdit } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import uuid from "react-uuid";
import { useUsers } from "../../../hooks/useUsers";

function EditUserModal({ titleText, btnText, formData, updatedData }) {
  const [show, setShow] = useState(false);
  const [name, setName] = useState(formData.name);
  const [email, setEmail] = useState(formData.email);
  const [telephone, setTelephone] = useState(formData.telephone);

  const [spinnerOn, setSpinnerOn] = useState(false);
  const [alert, setAlert] = useState(null);

  const { updateUsers } = useUsers();

  const handleClose = () => {
    setName(formData.name);
    setEmail(formData.email);
    setTelephone(formData.phoneNumber);
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSpinnerOn(true);
    const newUserData = {
      username: formData.username,
      name: name,
      email: email,
      telephone: telephone,
    };
    const response = await updateUsers(newUserData);
    if (response) {
      setSpinnerOn(false);
      updatedData(newUserData);
      setShow(false);
    } else {
      setSpinnerOn(false);
      setAlert({
        variant: "danger",
        message: "Error creating the store. Please try again.",
      });
    }
  };

  return (
    <>
      <Tooltip
        title="Edit User"
        placement="top"
        arrow
        //followCursor={true}
        leaveDelay={20}
      >
        <button action="edit" onClick={handleShow}>
          <FaRegEdit />
        </button>
      </Tooltip>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> {titleText} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Form id="editUserModal" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder={formData.username}
                disabled
                readOnly
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder={formData.name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder={formData.email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                type="text"
                placeholder={formData.telephone}
                onChange={(e) => {
                  setTelephone(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {spinnerOn ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" form="editUserModal" type="submit">
                {btnText}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditUserModal;
