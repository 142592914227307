import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import { Modal, ModalHeader, Form, Button, Row, Col } from "react-bootstrap";

const CreateNewItemModal = ({ product, handleNewProduct, onProductAdded }) => {
  const [show, setShow] = useState(false);

  const [additionalFields, setAdditionalFields] = useState([]);

  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productEAN, setProductEAN] = useState("");

  const addField = () => {
    const newField = { key: "", value: "" };
    setAdditionalFields([...additionalFields, newField]);
  };

  const handleFieldChange = (index, key, value) => {
    const updatedFields = [...additionalFields];
    updatedFields[index][key] = value;
    setAdditionalFields(updatedFields);
  };

  const removeField = (index) => {
    const updatedFields = [...additionalFields];
    updatedFields.splice(index, 1);
    setAdditionalFields(updatedFields);
  };

  const handleSave = () => {
    const newProduct = {
      product_id: productId,
      product_name: productName,
      product_ean: productEAN,
      image: "",
      ...additionalFields.reduce((acc, field) => {
        acc[field.key] = field.value;
        return acc;
      }, {}),
    };
    handleNewProduct(newProduct);
    onProductAdded({
      name: newProduct.product_name,
      id: newProduct.product_id,
    });
    // Do something with the newProduct object, like sending it to an API or updating state
    //console.log("New Product:", newProduct);
    //setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleModalClose = () => {
    setShow(false);
  };

  return (
    <>
      <Tooltip>
        <button
          action="edit"
          onClick={handleShow}
          style={{
            border: "none",
            backgroundColor: "white",
            marginTop: "4px",
            fontSize: "14px",
            color: "blue",
          }}
        >
          or Create New Product
        </button>
      </Tooltip>

      <Modal
        id="CreateNewItemModal"
        show={show}
        backdrop="static"
        onHide={handleModalClose}
        dialogClassName="createNewItemModal"
      >
        <ModalHeader closeButton>
          <Modal.Title>Add new product</Modal.Title>
        </ModalHeader>
        <Modal.Body>
          <Form>
            <Form.Group controlId="productId" style={{ marginBottom: "10px" }}>
              <Form.Label>Product ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product ID"
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              controlId="productName"
              style={{ marginBottom: "10px" }}
            >
              <Form.Label>Product Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
              />
            </Form.Group>

            <Form.Group
              controlId="productName"
              style={{ marginBottom: "10px" }}
            >
              <Form.Label>Product EAN</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product EAN"
                value={productEAN}
                onChange={(e) => setProductEAN(e.target.value)}
              />
            </Form.Group>

            {additionalFields.map((field, index) => (
              <Row key={index} style={{ marginBottom: "10px" }}>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Key"
                    value={field.key}
                    onChange={(e) =>
                      handleFieldChange(index, "key", e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter Value"
                    value={field.value}
                    onChange={(e) =>
                      handleFieldChange(index, "value", e.target.value)
                    }
                  />
                </Col>
                <Col>
                  <Button onClick={() => removeField(index)}>Remove</Button>
                </Col>
              </Row>
            ))}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "white", border: "2px solid green" }}
            onClick={addField}
          >
            {" "}
            Add more fields
          </Button>
          <Button style={{ border: "2px solid #ff4e16" }} onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateNewItemModal;
