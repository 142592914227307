import React, { useEffect, useState } from "react";
import "./storeSelection.scss";
import { DropdownButton, Dropdown } from "react-bootstrap";
import { useStores } from "../../hooks/useStores";
import uuid from "react-uuid";

const StoreSelection = ({ handleSecSelection, handleStoreSelection }) => {
  const [storeTitle, setStoreTitle] = useState("Select Store");
  const [isStoreSelected, setIsStoreSelected] = useState(false);
  const [chosenGond, setChosenGond] = useState("Select gondola");
  const [isGondSelected, setIsGondSelected] = useState(false);
  const [chosenSec, setChosenSec] = useState("Select section");
  const [isSecSelected, setIsSecSelected] = useState(false);

  const [stores, setStores] = useState([]);
  const [gondolas, setGondolas] = useState([]);
  const [sections, setSections] = useState([]);
  const [shelves, setShelves] = useState([]);
  const { getAllStores, getAllGondolas, getAllSections, getAllShelves, error } =
    useStores();

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const data = await getAllStores();
        setStores(data);
      } catch (error) {
        console.error("Error fetching stores:", error);
      }
    };
    fetchStores();
  }, []);

  const resetGondBtn = () => {
    setChosenGond("Select Gondola");
    setChosenSec("Select Sec");
    setIsGondSelected(false);
    setIsStoreSelected(false);
  };

  const resetSecBtn = () => {
    setChosenSec("Select Sec");
    setIsGondSelected(false);
  };

  const handleTitleSelect = async (selectedStore) => {
    handleStoreSelection(selectedStore);
    resetGondBtn();
    setStoreTitle(selectedStore);
    const response = await getAllGondolas(selectedStore);
    setGondolas(response);
    // TODO : handle error
    setIsStoreSelected(true);
  };

  const handleGondSelect = async (selectedGond) => {
    resetSecBtn();
    setChosenGond(selectedGond);
    const response = await getAllSections(selectedGond);
    setSections(response);
    // TODO : handle errors
    setIsGondSelected(true);
  };

  const handleSecSelect = async (selectedSec) => {
    handleSecSelection(selectedSec);
    setChosenSec(selectedSec);
    const response = await getAllShelves(selectedSec);
    setShelves(response);
    setIsSecSelected(true);
  };
  return (
    <div>
      <div className="planogramHeader">
        <p className="planoTitle">Please select a Store</p>
        <div className="dropdownBtns">
          <DropdownButton
            id="selectStore-btn"
            title={storeTitle}
            bsPrefix="planogramDropdownBtn"
            onSelect={handleTitleSelect}
          >
            {stores.map((store) => (
              <Dropdown.Item key={uuid()} eventKey={store.store_id}>
                {store.store_id}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        </div>
      </div>
    </div>
  );
};

export default StoreSelection;
