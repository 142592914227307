import { useState } from "react";
import { Button, Modal, Form, Spinner, Alert } from "react-bootstrap";
import uuid from "react-uuid";
import { useUsers } from "../../../hooks/useUsers";

function CreateUserModal({ newlyAddedUser }) {
  const [show, setShow] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [telephone, setTelephone] = useState("");
  const [userType, setUserType] = useState("Select User Type");

  const [spinnerOn, setSpinnerOn] = useState(false);
  const [alert, setAlert] = useState(null);

  const { createNewUser } = useUsers();

  const handleClose = () => {
    setUsername("");
    setName("");
    setPassword("");
    setEmail("");
    setTelephone("");
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userType === "Select User Type" || userType === "") {
      setAlert({
        variant: "danger",
        message: "Please select user type.",
      });
    } else {
      setSpinnerOn(true);
      const userData = {
        username: username.trim(),
        name: name.trim(),
        email: email.trim(),
        password: password.trim(),
        telephone: telephone.trim(),
        user_type: userType,
      };

      const response = await createNewUser(userData);
      if (response) {
        setSpinnerOn(false);
        newlyAddedUser(userData);
        setShow(false);
      } else {
        setSpinnerOn(false);
        setAlert({
          variant: "danger",
          message: "Error creating the user. Please try again.",
        });
      }
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        {"Create New User "}
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New User</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <Form id="createUserForm" onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Username</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Insert username"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Insert password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Insert email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>User Type</Form.Label>
              <Form.Select
                required
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
              >
                <option key={uuid()}>admin</option>
                <option key={uuid()}>user</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Insert name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId={uuid()}>
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                type="text"
                placeholder="Insert telephone"
                onChange={(e) => {
                  setTelephone(e.target.value);
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {spinnerOn ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" form="createUserForm" type="submit">
                {"Create User"}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CreateUserModal;
