import React, { useState, useRef, useEffect } from "react";
import { Tooltip } from "@mui/material";
import { GrPrevious, GrNext } from "react-icons/gr";

import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Alert,
  Carousel,
} from "react-bootstrap";
import { FaRegEdit, FaTrash } from "react-icons/fa";
import MasterDropdown from "../MasterDropdown";
import uuid from "react-uuid";
//import image1 from "../../../assets/example_images/image1.jpg";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "../../../utils/setCanvasPreview";
import "react-image-crop/src/ReactCrop.scss";
import "../modal.scss";
import { usePlanogram } from "../../../hooks/usePlanogram";
import { useMasterFile } from "../../../hooks/useMasterFile";
import CreateNewItemModal from "./CreateNewItemModal";

const EditPlaceholdersModal = ({
  placeholder,
  imgFilenamesList,
  handleUpdatedListFromPh,
}) => {
  const [show, setShow] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [crop, setCrop] = useState(null);
  const [showDropdowns, setShowDropdowns] = useState(true);
  //const [selectedImage, setSelectedImage] = useState(image1);
  const [rowData, setRowData] = useState([
    {
      id: uuid(),
      product: null,
      file: null,
      filename: null,
    },
  ]);
  const [currentRow, setCurrentRow] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [alert, setAlert] = useState(null);
  const [newCroppedImages, setNewCroppedImages] = useState([]);
  const [processingComplete, setProcessingComplete] = useState(false);

  const fileInputRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const imgRefs = imgFilenamesList.map(() => React.createRef());

  const handleCarouselSelect = (selectedIndex) => {
    const lastIndex = imgFilenamesList.length - 1;

    // Wrap around to the last image when going to the previous image from the first image
    const newSelectedIndex =
      selectedIndex < 0 ? lastIndex : selectedIndex % (lastIndex + 1);

    setSelectedImageIndex(newSelectedIndex);
  };

  const { uploadImg } = usePlanogram();

  const { addProd } = useMasterFile();

  const handleAddMore = () => {
    // Increase the dropdownCount when "Add more" is clicked
    const newRow = {
      id: uuid(),
      // Add other properties based on your requirements
    };
    setRowData((prevData) => [...prevData, newRow]);
  };

  const handleModalClose = () => {
    setShowImage(false);
    setShowDropdowns(true);
    setRowData([{ id: uuid() }]);
    //change this. the img will come through the props
    //setSelectedImage(image1);
    setAlert(null);
    setNewCroppedImages([]);
    setShow(false);
  };

  // We use the useEffect to call the handleSaveData
  // and we do not do it directly from the processInput
  // because we need to make sure that the newCroppedImages
  // list has been updated.
  useEffect(() => {
    if (processingComplete) {
      //console.log(newCroppedImages);
      handleSaveData();
      // Reset processingComplete for future calls
      setProcessingComplete(false);
    }
  }, [processingComplete]);

  const handleSaveData = () => {
    //console.log(newCroppedImages);
    handleUpdatedListFromPh(newCroppedImages, placeholder.id);
    handleModalClose();
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e, index) => {
    const updatedRowData = rowData.map((row) =>
      row.id === index
        ? {
            ...row,
            file: e.target.files[0],
            filename: `${index}.png`,
          }
        : row
    );
    setRowData(updatedRowData);

    //console.log("Selected file:", selectedFile.name);
  };

  const handleCropImage = (index) => {
    // You can customize this logic based on the react-image-crop documentation
    // This example uses the basic functionality of react-image-crop
    // Make sure to handle the cropped image and update the state
    // (e.g., save it to the state or upload it to the server)
    // For simplicity, we're just logging the crop details here.
    setCurrentRow(index);
    setShowImage(true);
    setShowDropdowns(false);
    //console.log("Cropped Image:", croppedImage);
  };

  const handleCropComplete = (crop, pixelCrop) => {
    // You can perform additional logic when the crop is completed
    //console.log("Crop complete:", crop, pixelCrop);
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;

    const crop = makeAspectCrop(
      {
        unit: "%",
        width: 25,
      },
      width,
      height
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleDeleteRow = (index) => {
    const updatedRowData = rowData.filter((row) => row.id !== index);
    setRowData(updatedRowData);
  };

  const handleProductChangeFromMaster = (prod, key) => {
    setSelectedProduct(prod);
    const updatedRowData = rowData.map((row) =>
      row.id === key
        ? {
            ...row,
            product: prod,
          }
        : row
    );
    setRowData(updatedRowData);
  };

  const handleNewProductCreated = async (newProduct) => {
    //console.log(newProduct);
    await addProd(newProduct);
  };

  const processInput = async () => {
    const storeID = placeholder.store_id;
    const numberOfNewProducts = rowData.length;
    let widthPerProd = placeholder.width / numberOfNewProducts;
    // minimize decimals
    widthPerProd = Math.round(widthPerProd * 100) / 100;
    const initialX = placeholder.positionX;
    let currentPosX = 0.0;

    let imgName = "";
    const newImages = [];

    for (const [index, row] of rowData.entries()) {
      const rowFile = row.file;
      if (rowFile instanceof File) {
        imgName = rowFile.name;
        await uploadImg(storeID, rowFile);
      } else if (typeof rowFile === "string" && rowFile.startsWith("data:")) {
        // The file property is a base64 string, convert it to a File
        const base64Data = rowFile.split(";base64,")[1];
        const binaryString = atob(base64Data);
        const uint8Array = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
          uint8Array[i] = binaryString.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], {
          type: row.filename
            ? row.filename.split(".").pop()
            : "application/octet-stream",
        });
        const file = new File([blob], row.filename || "file", {
          type: blob.type,
        });
        imgName = file.name;
        await uploadImg(storeID, file);
      } else {
        setAlert({
          variant: "danger",
          message: "Please choose an image.",
        });
        console.log("Invalid file format");
      }

      if (currentPosX === 0) {
        currentPosX = initialX;
      } else {
        currentPosX = currentPosX + widthPerProd;
      }

      const new_cropped_img = {
        id: uuid(),
        section_id: placeholder.section_id,
        store_id: placeholder.store_id,
        gondola_id: placeholder.gondola_id,
        width: widthPerProd,
        height: placeholder.height,
        positionX: currentPosX,
        positionY: placeholder.positionY,
        shelf: placeholder.shelf,
        positionShelf: placeholder.positionShelf + index,
        ir_matches: {
          0: {
            PRODUCT_NAME: row.product["name"],
            Similarity: 100.0,
            PRODUCT_ID: row.product["id"],
            IR_distance: 0.1,
          },
        },
        ocr_matches: {
          0: {
            PRODUCT_ID: row.product["id"],
            PRODUCT_NAME: row.product["name"],
            String_distance: 1.0,
            Similarity: 100.0,
            hasMasterImage: true,
          },
        },
        img_path: `${placeholder.store_id}/cropped_images/uploaded_images/${imgName}`,
        type: "image",
      };
      newImages.push(new_cropped_img);
    }
    setNewCroppedImages(newImages);
    setProcessingComplete(true);
  };

  const handleShow = () => setShow(true);

  return (
    <>
      <Tooltip title="Edit Placeholder" placement="top" arrow leaveDelay={20}>
        <button action="edit" onClick={handleShow}>
          <FaRegEdit />
        </button>
      </Tooltip>

      <Modal
        id="editPlaceholderModal"
        show={show}
        onHide={handleModalClose}
        backdrop="static"
        dialogClassName="placeholderModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Placeholders</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert(null)}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          <p>
            Choose from the dropdown menu the product that corresponds to the
            placeholder and upload an image. If more than one products belong to
            this placeholder press the add more button.
          </p>
          {showImage && (
            <Container style={{ marginTop: "0px", position: "relative" }}>
              <Row style={{ marginBottom: "15px" }}>
                <ReactCrop
                  crop={crop}
                  minWidth={"100px"}
                  onChange={(pixelCrop, percentageCrop) =>
                    setCrop(percentageCrop)
                  }
                  onComplete={(crop) => handleCropComplete(crop)}
                  style={{ zIndex: 1 }} // Set a higher z-index for the cropping area
                >
                  <Carousel
                    interval={null}
                    activeIndex={selectedImageIndex}
                    onSelect={handleCarouselSelect}
                    style={{
                      zIndex: 0, // Set a lower z-index for the carousel
                      position: "static", // Make sure the controls don't overlay
                    }}
                    controls={false} // Hide the default controls
                    indicators={false} // Disable the indicators
                  >
                    {imgFilenamesList.map((image, index) => (
                      <Carousel.Item key={index}>
                        <img
                          // {/images/{storeID}/photos_from_mobile/section/}
                          ref={imgRefs[index]}
                          src={`/media/${placeholder.store_id}/photos_from_mobile/${placeholder.gondola_id}/${image}`}
                          alt={`Image ${index + 1}`}
                          style={{ width: "100%", height: "100%" }}
                          onLoad={onImageLoad}
                        />
                        {/* <Carousel.Caption>
                          <p style={{ color: "#ff4e16", fontWeight: "700" }}>
                            {image}
                          </p>
                        </Carousel.Caption> */}
                      </Carousel.Item>
                    ))}
                  </Carousel>
                  {/* <img
                    ref={imgRef}
                    src={`/media/${store}/photos_from_mobile/${gond}/${image}`}
                    style={{ width: "100%", height: "100%" }}
                    onLoad={onImageLoad}
                  /> */}
                </ReactCrop>
              </Row>

              <Row>
                {/* Custom controls */}
                <div id="carouselButtons">
                  {/* Previous Image Button with Tooltip */}
                  <Tooltip title="Previous Image" arrow>
                    <Button
                      className="carouselButton"
                      variant="primary"
                      onClick={() =>
                        handleCarouselSelect(selectedImageIndex - 1)
                      }
                    >
                      <GrPrevious />
                    </Button>
                  </Tooltip>

                  {/* Next Image Button with Tooltip */}
                  <Tooltip title="Next Image" arrow>
                    <Button
                      className="carouselButton"
                      variant="primary"
                      onClick={() =>
                        handleCarouselSelect(selectedImageIndex + 1)
                      }
                    >
                      <GrNext />
                    </Button>
                  </Tooltip>
                </div>

                <div id="cropControls">
                  <Col style={{ marginRight: "0px" }}>
                    {" "}
                    <Button
                      style={{
                        backgroundColor: "white",
                        border: "solid 1px #ff4e16",
                      }}
                      onClick={() => {
                        const selectedImgRef = imgRefs[selectedImageIndex];
                        setCanvasPreview(
                          selectedImgRef.current,
                          previewCanvasRef.current,
                          convertToPixelCrop(
                            crop,
                            selectedImgRef.current.width,
                            selectedImgRef.current.height
                          )
                        );
                        const finalCroppedImage =
                          previewCanvasRef.current.toDataURL();
                        const updatedRowData = rowData.map((row) =>
                          row.id === currentRow
                            ? {
                                ...row,
                                file: finalCroppedImage,
                                filename: `${currentRow}.png`,
                              }
                            : row
                        );
                        setRowData(updatedRowData);
                        setShowImage(false);
                        setCrop(null);
                        setShowDropdowns(true);
                      }}
                    >
                      Save Cropped Image
                    </Button>
                    <Button
                      style={{
                        marginLeft: "20px",
                        backgroundColor: "white",
                        border: "solid 1px green",
                      }}
                      onClick={() => {
                        const selectedImgRef = imgRefs[selectedImageIndex];
                        setCanvasPreview(
                          selectedImgRef.current,
                          previewCanvasRef.current,
                          convertToPixelCrop(
                            crop,
                            selectedImgRef.current.width,
                            selectedImgRef.current.height
                          )
                        );
                      }}
                    >
                      Preview Cropped Image
                    </Button>
                    <Button
                      style={{ marginLeft: "20px" }}
                      onClick={() => {
                        setShowImage(false);
                        setCrop(null);
                        setShowDropdowns(true);
                      }}
                    >
                      Back
                    </Button>
                  </Col>
                </div>
              </Row>
              <Row>
                {crop && (
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      //display: "none",
                      border: "1px solid black",
                      objectFit: "scale-down",
                      width: 200,
                      height: 200,
                    }}
                  />
                )}
              </Row>
            </Container>
          )}
          {showDropdowns && (
            <Container style={{ marginTop: "0px" }}>
              {rowData.map((row) => (
                <Row key={row.id} style={{ marginBottom: "10px" }}>
                  {row.product ? (
                    <Col style={{ marginTop: "15px", color: "green" }}>
                      {" "}
                      <p>{row.product["name"]}</p>
                    </Col>
                  ) : (
                    <Col>
                      <MasterDropdown
                        onProductChange={(product) =>
                          handleProductChangeFromMaster(product, row.id)
                        }
                      />
                      <CreateNewItemModal
                        product={placeholder}
                        handleNewProduct={handleNewProductCreated}
                        onProductAdded={(product) =>
                          handleProductChangeFromMaster(product, row.id)
                        }
                      />
                    </Col>
                  )}

                  <Col>
                    <Button
                      onClick={handleUploadClick}
                      style={{
                        marginTop: "10px",
                        backgroundColor: "white",
                        border: "2px solid #ff4e16",
                      }}
                    >
                      Upload Image
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      ref={fileInputRef}
                      onChange={(e) => handleFileChange(e, row.id)}
                    />
                  </Col>
                  <Col>
                    {" "}
                    <Button
                      onClick={() => handleCropImage(row.id)}
                      style={{
                        marginTop: "10px",
                        backgroundColor: "white",
                        border: "2px solid #f4c430",
                      }}
                    >
                      Crop Section Image
                    </Button>
                  </Col>
                  <Col style={{ marginTop: "15px" }}>
                    {row.filename ? (
                      <p>{row.filename}</p>
                    ) : (
                      <p>Selected image file</p>
                    )}
                  </Col>
                  <Col style={{ marginTop: "15px" }}>
                    <FaTrash
                      onClick={() => handleDeleteRow(row.id)}
                      style={{ color: "red" }}
                    />
                  </Col>
                </Row>
              ))}
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "white", border: "2px solid green" }}
            onClick={handleAddMore}
          >
            Add more products
          </Button>
          <Button
            style={{ border: "2px solid #ff4e16" }}
            onClick={() => {
              if (rowData.some((row) => !row.product)) {
                setAlert({
                  variant: "danger",
                  message: "Please select a product.",
                });
              }
              //console.log(rowData);
              processInput();
              // rowData.map((row) => processInput(row));
              //handleModalClose();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditPlaceholdersModal;
