import React, { useState } from "react";
import Layout from "../../layout/Layout/Layout";
import "./planogram.scss";
import StoreSelection from "../../components/Tables/StoreSelection";
import PlanogramComponent from "../../components/PlanogramComponent/PlanogramComponent";

const Planogram = () => {
  const [selectedSec, setSelectedSec] = useState(null);

  const handleSecSelection = (sec) => {
    setSelectedSec(sec);
  };

  return (
    <Layout>
      <div className="planogramContainer">
        <StoreSelection
          handleSecSelection={handleSecSelection}
        ></StoreSelection>
        <PlanogramComponent selectedSec={selectedSec}></PlanogramComponent>
      </div>
    </Layout>
  );
};

export default Planogram;