import { useLocalStorage } from "./useLocalStorage";
import {
  uploadMaster,
  getLastTimeUpdated,
  addNewProd,
} from "../services/api/masterFileService";
import { useState } from "react";

export const useMasterFile = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getItem } = useLocalStorage();

  const uploadNewMasterFile = async (data) => {
    setError(false);

    try {
      setLoading(true);
      const response = await uploadMaster(getItem("token"), data);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const lastTimeUpdated = async () => {
    setError(false);
    try {
      setLoading(true);
      const response = await getLastTimeUpdated(getItem("token"));
      setLoading(false);
      return response;
    } catch (erro) {
      setLoading(false);
      setError(true);
    }
  };

  const addProd = async (newProd) => {
    setError(false);
    try {
      setLoading(true);
      const response = await addNewProd(getItem("token"), newProd);
      setLoading(false);
      return response;
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  return { uploadNewMasterFile, lastTimeUpdated, addProd, loading };
};
