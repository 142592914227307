import axios from "axios";
import * as Constants from "../../utils/constants";

export const planogramShelves = async (token, sec) => {
  try {
    const response = axios.post(
      Constants.PLANOGRAM_SHELVES,
      { section_id: sec },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const croppedImages = async (token, sec) => {
  try {
    const response = await axios.post(
      Constants.CROPPED_IMAGES,
      { section_id: sec },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const deleteACroppedImg = async (token, id) => {
  const params = {
    id: id,
  };
  try {
    const response = await axios.delete(Constants.CROPPED_IMAGES, {
      params: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const updateCroppedImgs = async (token, imgList) => {
  try {
    const response = await axios.put(
      Constants.CROPPED_IMAGES,
      { img_list: imgList },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    //console.log(response);
    return response;
  } catch (error) {
    console.log(error);
    return error.message;
  }
};

export const placeholders = async (token, sec) => {
  try {
    const response = axios.post(
      Constants.PLACEHOLDERS,
      { section_id: sec },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const del_placeholder = async (token, id) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const params = {
    id: id,
  };

  try {
    const response = axios.delete(Constants.PLACEHOLDERS, {
      headers: headers,
      params: params,
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const img_filenames = async (token, sec) => {
  try {
    const response = axios.post(
      Constants.IMG_FILENAMES,
      { section_id: sec },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const createPlanoImg = async (token, data) => {
  try {
    const response = await axios.post(
      Constants.CREATE_PLANO_IMG,
      { img: data },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response;
  } catch (error) {
    return error.message;
  }
};

export const getPlanoImg = async (token, sec) => {
  try {
    const response = axios.post(
      Constants.GET_PLANO_IMG,
      { section_id: sec },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.message;
  }
};

export const uploadNewImg = async (token, storeId, file) => {
  try {
    const formData = new FormData();
    formData.append("storeID", storeId);
    formData.append("file", file);
    const response = await axios.post(Constants.UPLOAD_IMG, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response);
    return response.status;
  } catch (error) {
    return error.message;
  }
};
