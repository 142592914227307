import React from "react";
//import { FaArrowUpRightDots } from "react-icons/fa6";
import { useStores } from "../../hooks/useStores";
import { useExports } from "../../hooks/useExports";
import "./exports.scss";
import "./exportsCards.scss";

const ExportsCards = ({
  title,
  img,
  desc,
  chosenFile,
  storeId,
  downloadingFiles,
}) => {
  const { getStoreConfig } = useStores();
  const { getSalesFile, getNewMaster } = useExports();
  const handleOnClick = async () => {
    var filename = "";

    switch (chosenFile) {
      case "sales":
        filename = `updatedSalesFile_${storeId}.xlsx`;
        try {
          downloadingFiles(true);
          const response = await getSalesFile(storeId);
          downloadingFiles(false); // Fetch the Excel file from the server
          const url = URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        } catch (error) {
          downloadingFiles(false);
          console.error("Error downloading file:", error);
        }

        break;
      case "master":
        //url = "http://91.184.203.22:2003/export-new-master";
        //mediaType = "application/json"
        filename = `newMaster_${storeId}.json`;
        downloadingFiles(true);
        const responseMaster = await getNewMaster();
        downloadingFiles(false);
        const jsonContent = JSON.stringify(responseMaster, null, 2);
        const blobMaster = new Blob([jsonContent], {
          type: "application/json",
        });
        const url = URL.createObjectURL(blobMaster);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        break;
      case "config":
        filename = `storeConfiguration_${storeId}.json`;
        downloadingFiles(true);
        const response = await getStoreConfig(storeId);
        downloadingFiles(false);
        const jsonData = JSON.stringify(response); // Convert JSON data to a string
        const blob = new Blob([jsonData], { type: "application/json" });

        const href = URL.createObjectURL(blob);
        const a = Object.assign(document.createElement("a"), {
          href,
          style: "display:none",
          download: filename,
        });
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(href);
        a.remove();
        break;
      default:
      //url = "";
      //mediaType = ''
      // filename = "";
    }
  };

  return (
    <div className="card">
      <div className="cardBody">
        <img src={img} className="cardImg" alt="" width={50} height={50} />
        <h2 className="cardTitle">{title}</h2>
        <p className="cardDesc">{desc}</p>
      </div>
      <button className="cardBtn" onClick={handleOnClick}>
        Download
      </button>
    </div>
  );
};

export default ExportsCards;
