import axios from "axios";
import * as Constants from "../../utils/constants";

export const getUsers = async (token) => {
  try {
    const response = await axios.get(Constants.GET_USERS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const updateUser = async (token, newUserData) => {
  try {
    const response = await axios.put(Constants.UPDATE_USERS, newUserData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const createUser = async (token, userData) => {
  try {
    const response = await axios.post(Constants.CREATE_USER, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const getUnames = async (token) => {
  try {
    const response = await axios.get(Constants.GET_UNAMES, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};

export const deleteUser = async (token, username) => {
  try {
    const response = await axios.delete(Constants.DELETE_USER, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        username: username,
      },
    });
    return response;
  } catch (error) {
    return error.message;
  }
};
