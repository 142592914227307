import React, { useEffect, useState } from "react";
import "./products.scss";
import Layout from "../../layout/Layout/Layout";
import { Container, Row, Carousel } from "react-bootstrap";
import TableProducts from "../../components/Tables/TableProducts";
import StoreSelection from "../../components/Tables/StoreSelection";
import { usePlanogram } from "../../hooks/usePlanogram";

const Products = () => {
  /* carousel with table selection start */
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleCarouselSelect = (selectedIndex) => {
    setSelectedImageIndex(selectedIndex);
  };

  /* carousel with table selection end */

  const [selectedSec, setSelectedSec] = useState(null);
  const [croppedImagesList, setCroppedImagesList] = useState([]);

  const [imgFilenamesList, setImgFilenameList] = useState([]);
  const [imgFilenamesListMobile, setImgFilenameListMobile] = useState([]);
  const [store, setStore] = useState(null);
  const [gond, setGond] = useState(null);
  const { getCroppedImages, getFilenames } = usePlanogram();

  const handleSecSelection = (sec) => {
    setSelectedSec(sec);
  };

  useEffect(() => {
    if (selectedSec !== null) {
      const fetchImgFilenames = async () => {
        const data = await getFilenames(selectedSec);
        setStore(data["storeId"]);
        setGond(data["gondola"]);
        setImgFilenameList(data["filenames"]);
        setImgFilenameListMobile(data["filenames_mobile"]);
        //console.log(imgFilenamesList);
      };

      const fetchCroppedImages = async () => {
        const images = await getCroppedImages(selectedSec);
        setCroppedImagesList(images);
      };

      fetchImgFilenames();
      fetchCroppedImages();
    }
  }, [selectedSec]);

  return (
    <Layout>
      <div className="planogramContainer">
        <p>
          *User can choose from stores that have already been configured. If
          none, dropdown menus are unavailable.
        </p>
        <StoreSelection
          handleSecSelection={handleSecSelection}
        ></StoreSelection>
        <div>
          <Container style={{ marginTop: 10 }}>
            <Row>
              {selectedSec && (
                <Carousel
                  interval={null} // disable auto slide, default is 5 seconds (5000)
                  activeIndex={selectedImageIndex}
                  onSelect={handleCarouselSelect}
                >
                  {imgFilenamesList.map((image, index) => (
                    <Carousel.Item key={index}>
                      <img
                        src={`/media/${store}/visualizations/${gond}/${selectedSec}/${image}`}
                        height={"300px"}
                        width={"1000px"}
                        alt=""
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </Row>
          </Container>

          {selectedSec && (
            <div className="tableSection">
              <TableProducts
                imgs={croppedImagesList}
                filenames={imgFilenamesList}
                filenamesMobile={imgFilenamesListMobile}
                store={store}
              />
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Products;
